import React, {useState} from "react";

const title = "TO ALL STUDENTS:"
// const message = "Your library ID is now available for pick-up, please approach your beautiful librarian"
const message = ""

const Announcement = () => {

  if(message == "") {
    return null
  } else {
    return(
      <div className="library-announcement">
        <h3>{title}</h3>
        <p>{message}</p>
      </div>
    )
  }
  

}
export default Announcement;