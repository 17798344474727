import ReactOnRails from 'react-on-rails';

import HelloWorld from '../bundles/HelloWorld/components/HelloWorld';
import WeeklyAttendance from '../components/weekly_attendance/Main';
import ManageUsers from '../components/manage/users/Main';
import ManageSchools from '../components/manage/schools/Main';
import ManageSchoolUsers from '../components/manage/school_users/Main';
import ManageSchoolStudents from '../components/manage/school_students/Main';
import ManageSchoolStaffs from '../components/manage/school_staffs/Main';
import ManageSchoolLibraries from '../components/manage/school_libraries/Main';
import ManageFeaturedBooks from '../components/manage/featured_books/Main';
import ManageSchoolLibrarySettings from '../components/manage/school_library_settings/Main';
import ManageTests from '../components/manage/tests/Main';
import TestRespondents from '../components/manage/test_respondents/Main';
import TestCharts from '../components/manage/test_charts/Main';
import TakeTest from '../components/take_test/Main';
import GateLogs from '../components/gate_logs/Main';
import EntryGateLogs from '../components/entry_gate_logs/Main';
import AttendanceLibrary from '../components/attendance/library/Main';
import AttendanceStudents from '../components/attendance/students/Main';
import AttendanceStaffs from '../components/attendance/staffs/Main';
 
// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  HelloWorld,
  WeeklyAttendance,
  ManageUsers,
  ManageSchools,
  ManageSchoolUsers,
  ManageSchoolStudents,
  ManageSchoolStaffs,
  ManageSchoolLibraries,
  ManageSchoolLibrarySettings,
  ManageFeaturedBooks,
  ManageTests,
  TestRespondents,
  TestCharts,
  TakeTest,
  GateLogs,
  EntryGateLogs,
  AttendanceLibrary,
  AttendanceStudents,
  AttendanceStaffs,
});
