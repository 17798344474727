import React, { useEffect, useState } from 'react';
import Loading from './_loading';

const Completed = ({test_id}) => {
  const [data, setData] = useState({});
  const [answers, setAnswers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    // fetch data from server
    setLoading(true);
    fetch(`/api/v1/tests/${test_id}/summary`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data.data)
        setData(data.data);
        setAnswers(data.data.answers);
        setLoading(false);
      }
    );
  };

  const DataInformation = () => {
    return (
      <div class="row">
      <div class="col-md-6">
        <div class="mb-3">
          <label for="testName" class="form-label">Name:</label>
          <input type="text" id="testName" class="form-control" value={data.respondent} readonly />
        </div>
        <div class="mb-3">
          <label for="numQuestions" class="form-label">Number of Questions:</label>
          <input type="number" id="numQuestions" class="form-control" value={data.number_of_questions} readonly />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="startedAt" class="form-label">Started At:</label>
          <input type="text" id="startedAt" class="form-control" value={data.started_at} readonly />
        </div>
        <div class="mb-3">
          <label for="completedAt" class="form-label">Completed At:</label>
          <input type="text" id="completedAt" class="form-control" value={data.completed_at} readonly />
        </div>
      </div>
    </div>
    )
  }

  const Answers = () => {
    return (
      <div class="card">
        <div class="card-header">
          <h2 class="card-title">Answer Summary</h2>
        </div>
        <div class="card-body">
          <ol>
            {answers.map((answer, index) => {
              return (
                <li>
                  <h5>{answer.question}</h5>
                  <p>Selected Answer: {answer.answer}</p>
                </li>
              )
            })}
          </ol>
        </div>
      </div>
    )
  }

  return(<>
    {loading && <Loading />}
    {!loading && <DataInformation />}
    {!loading && <Answers />}
  </>)

};

export default Completed;