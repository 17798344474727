export const API_URL = "/api/v1/tests/:test_id/test_respondents/charts";
export const LINK_URL = "/manage/tests";

export const ROWS_PER_PAGE = 20;

export const CREATE_FIELDS = [
  {name: "title", label: "Title", type: "text"},
  {name: "description", label: "Description", type: "text"},
]

export const CREATE_FORM_DATA_DEFAULT = {
  title: "",
  description: "",
};

export const LIST_COLUMNS = [
  { id: "last_name", label: "Last Name" },
  { id: "first_name", label: "First Name" },
  { id: "general_position", label: "Position" },
  { id: "school_name", label: "School" },
  { id: "status", label: "Status" },
  { id: "progress", label: "Progress" },
  { id: "actions", label: "Actions" },
]

export const UPDATE_FIELDS = [
];

export const SHOW_FIELDS = [
  {name: "scores_per_strands", label: "label", value: "value", type: "unordered_list"},
  {name: "answers", label: "question", value: "answer", type: "unordered_list"},
]