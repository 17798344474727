export const API_URL = "/api/v1/exams";
export const LINK_URL = "/manage/tests";

export const ROWS_PER_PAGE = 10;

export const CREATE_FIELDS = [
  {name: "title", label: "Title", type: "text"},
  {name: "description", label: "Description", type: "text"},
]

export const CREATE_FORM_DATA_DEFAULT = {
  title: "",
  description: "",
};

export const LIST_COLUMNS = [
  { id: "id", label: "ID" },
  { id: "title", label: "Title", type: "link" },
  { id: "description", label: "Description" },
  { id: "status", label: "Status" },
  { id: "actions", label: "Actions" },
]

export const UPDATE_FIELDS = [
  {name: "title", label: "Title", type: "text"},
  {name: "description", label: "Description", type: "text"},
];

export const SHOW_FIELDS = [
  {name: "id", label: "ID", type: "text"},
  {name: "title", label: "Title", type: "text"},
  {name: "description", label: "Description", type: "text"},
  {name: "status", label: "Status", type: "text"},
];
