import React, { useEffect, useState } from "react";
import ModeAds from "./_mode_ads";
import ModeScan from "./_mode_scan";
import Scanner from "./_scanner";

const Main = ({ gate_id, gate_name, youtube_id }) => {
  const [mode, setMode] = useState("ads");
  const [latest_log, setLatestLog] = useState({});
  
  // var theinterval = null

  const onScan = (value) => {
    switcherToScan()
    submitLog(value)
  }

  const switcherToScan = () => {
    setMode("scan")
    clearInterval(window.theinterval)
    window.theinterval = setInterval(() => {
      setMode("ads")
      setLatestLog({})
    }, 10000);
  }

  const submitLog = (value) => {
    fetch(`/school_gates/gate_logs`, {
      method: "POST",
      headers: { "Content-Type": "application/json", },
      body: JSON.stringify({
        gate_id: gate_id,
        rfid: value,
      }),
    })
    .then((resp) => {
      if (resp.ok) { return resp.json(); }
      setLatestLog({
        error: "Network response was not ok"
      })
      throw new Error("Network response was not ok.");
    })
    .then((resp) => {
      console.log("here:", resp)
      if (resp.status == "ok") {
        setLatestLog(resp.data)
      } else if (resp.status == "error") {
        setLatestLog({
          error: resp.error
        })
      } else {
        setLatestLog({
          error: "Please Try Again"
        })
      }
      // testEntry()
    })
    .catch((error) => {
      console.log("Catch: ", error.message)
    });
  }

  const onCommand = (value) => {
    if (value == "ads") {
      setMode("ads")
    } else if (value == "scan") {
      setMode("scan")
    }
  }

  return(
    <div>
      <ModeAds mode={mode} gate_id={gate_id} youtube_id={youtube_id} />
      <ModeScan mode={mode} gate_name={gate_name} latest_log={latest_log} />
      <Scanner onScan={onScan} onCommand={onCommand} />
    </div>
  )
}

export default Main;
