export const API_URL = "/api/v1/users";

export const ROWS_PER_PAGE = 10;

export const CREATE_FIELDS = [
  {name: "first_name", label: "First Name", type: "text"},
  {name: "last_name", label: "Last Name", type: "text"},
  {name: "email", label: "Email", type: "email"},
]

export const CREATE_FORM_DATA_DEFAULT = {
  first_name: "",
  last_name: "",
  email: "",
};

export const LIST_COLUMNS = [
  { id: "id", label: "ID" },
  { id: "last_name", label: "Last Name" },
  { id: "first_name", label: "First Name" },
  { id: "email", label: "Email" },
  { id: "school_names", label: "School" },
  { id: "school_roles", label: "Role" },
  { id: "actions", label: "Actions" },
]

export const UPDATE_FIELDS = [
  {name: "first_name", label: "First Name", type: "text"},
  {name: "last_name", label: "Last Name", type: "text"},
  {name: "email", label: "Email", type: "email"},
  {name: "photo", label: "Photo", type: "file_upload", url: `${API_URL}/:id/upload_photo`},
];

export const SHOW_FIELDS = [
  { name: "id", label: "ID", type: "text" },
  { name: "last_name", label: "Last Name", type: "text" },
  { name: "first_name", label: "First Name", type: "text" },
  { name: "email", label: "Email", type: "text" },
  { name: "profile_picture", label: "Photo", type: "image_viewer" },
]
