import React from 'react';
import { Card, CardContent, CardActions, Button, Typography } from '@mui/material';
import Progress from './_progress';

const CompleteIt = ({ onSubmit, onBack }) => {
  return (<>
    <Progress current_index={100} number_of_questions={100} />
    <Card>
      <CardContent>
        <h5 class="h4 mb-3">You have answered all the questions!</h5>
        <p class="lead">Please press "Submit Assessment" to complete.</p>
      </CardContent>
      <CardActions>
        <button class="btn btn-primary btn-lg" onClick={onSubmit}>
          Submit Assessment
        </button>

        <button class="btn btn-outline-secondary" onClick={onBack}>
          back
        </button>
      </CardActions>
    </Card>
  </>);
};

export default CompleteIt;