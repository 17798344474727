import React, { useEffect, useState } from "react";
import Unstarted from "./_unstarted";
import Test from "./_test";
import Loading from "./_loading"

const Main = ({test_id}) => {
  const [progress, setProgress] = useState('in_progress')

  return (<>
    {progress === 'loading' && <Loading />}
    {progress === 'unstarted' && <Unstarted onStartTest={() => setProgress('in_progress')} />}
    {progress === 'in_progress' && <Test test_id={test_id} onComplete={() => setProgress('complete')} />}
  </>)
}

export default Main;
