import React, { useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import Template from '../_common/Template';
import {
  API_URL,
  LINK_URL,
  ROWS_PER_PAGE,
  LIST_COLUMNS,
  CREATE_FIELDS,
  CREATE_FORM_DATA_DEFAULT,
  UPDATE_FIELDS,
  SHOW_FIELDS
} from "./__def";

const Main = ({ test_id }) => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [strands_average, setStrandsAverage] = React.useState(null);
  const [strands_charts, setStrandsCharts] = React.useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    fetch(API_URL.replace(":test_id", test_id), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((resp) => {
      if (resp.ok) { return resp.json(); }
      throw new Error("Network response was not ok.");
    })
    .then((resp) => {
      console.log("here:", resp)

      setStrandsAverage(resp.strands_average)
      setStrandsCharts(resp.strands_charts)
      console.log(resp.strands_average)
      setLoading(false);
    })
    .catch((error) => {
      console.log("Catch: ", error.message)
    });
  }

  return (
    <div class="p-5">
      <h3>Summary Report</h3>
      {loading && <p>Loading...</p>}
      {!loading && <ReactEcharts option={strands_average} />}

      {strands_charts.map((strand_chart, index) => {
        return(
          <div>
            <h3>{strand_chart.name}</h3>
            <div class="d-flex">
              <div class="flex-grow-1">
                {!loading && <ReactEcharts option={strand_chart.charts_strand_distribution} />}
              </div>
              <div class="flex-grow-1">
                {!loading && <ReactEcharts option={strand_chart.charts_strands_average_per_question} />}
              </div>
            </div>
          </div>
        )
      })}
      
    </div>
  )
}

export default Main;
