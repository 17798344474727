import React from 'react';

const Progress = ({ current_index, number_of_questions }) => {
  return (
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="progress">
          <div class="progress-bar" role="progressbar" style={{width: `${parseInt((current_index / number_of_questions) * 100)}%`}} aria-valuenow={current_index} aria-valuemin="0" aria-valuemax={number_of_questions}></div>
        </div>
      </div>
    </div>
  );
};

export default Progress;