import React from "react";
import { Card, Box, Alert } from '@mui/material';
import moment from "moment";


const ModeScan = ({ mode, gate_name, latest_log }) => {

  const theStyle = (mode == "scan") ? {display: "block"} : {display: "none"}

  const entryInfo = () => {
    return (
      <div style={{display: 'flex', padding: "50px 40px"}}>
        <Card style={{marginLeft: '20px', padding: '20px'}}>
          {/* <img src="https://s3-ap-southeast-1.amazonaws.com/eskwela-public/v1/assets/default-user.png" style={{width: '400px'}} /> */}
          <img src={latest_log.user.image} style={{width: '300px'}} />
          {/* <img src="http://localhost:3000/boy-test.png" style={{width: '400px'}} /> */}
        </Card>
        <div style={{marginLeft: '50px'}}>
          <Box sx={{typography: 'body1', fontSize: 40, marginBottom: 2}} className="chalk">Welcome to {gate_name}</Box>
          <Box sx={{typography: 'subtitle2', fontSize: 75}} className="chalk">{latest_log.user.first_name}</Box>
          <Box sx={{fontSize: 20, color: 'white'}}>Log At: {moment(latest_log.created_at).format("dddd, MMMM Do YYYY, h:mm:ss a")}</Box>
        </div>
      </div>
    )
  }

  const errorInfo = () => {
    return (
      <Alert variant="filled" severity="error">
        {latest_log.error}
      </Alert>
    )
  }

  return(
    <div style={theStyle} className="gate-body">
      <div style={{marginBottom: '10px', marginTop: '0'}}>
        {latest_log?.id && entryInfo()}
        {latest_log?.error && errorInfo()}
      </div>
    </div>
  )
}

export default ModeScan;
