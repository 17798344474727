export const API_URL = "/api/v1/schools/:school_id/users";

export const ROWS_PER_PAGE = 10;

export const CREATE_FIELDS = [
  {name: "first_name", label: "First Name", type: "text"},
  {name: "last_name", label: "Last Name", type: "text"},
  {name: "email", label: "Email", type: "email"},
  {name: "general_position", label: "Position", type: "dropdown", options: [
    {value: "principal", label: "Principal"},
    {value: "teacher", label: "Teacher"},
  ]},
]

export const CREATE_FORM_DATA_DEFAULT = {
  first_name: "",
  last_name: "",
  email: "",
  general_position: "principal",
};

export const LIST_COLUMNS = [
  { id: "last_name", label: "Last Name" },
  { id: "first_name", label: "First Name" },
  { id: "email", label: "Email" },
  { id: "general_position", label: "Position" },
  { id: "actions", label: "Actions" },
]

export const UPDATE_FIELDS = [
  {name: "first_name", label: "First Name", type: "text"},
  {name: "last_name", label: "Last Name", type: "text"},
  {name: "email", label: "Email", type: "email"},
  {name: "general_position", label: "Position", type: "dropdown", options: [
    {value: "principal", label: "Principal"},
    {value: "teacher", label: "Teacher"},
  ]},
];
