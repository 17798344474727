import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardActions, Button, Typography } from '@mui/material';
import Question from './_question';
import CompleteIt from './_complete_it';
import Loading from "./_loading"
import Completed from "./_completed"
// import { questions } from './__sample_data';

const Test = ({ test_id, onComplete }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [test_status, setTestStatus] = useState('');

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    // fetch data from server
    setLoading(true);
    fetch(`/api/v1/tests/${test_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        setQuestions(data.questions);
        setUserAnswers(data.user_answers);
        setCurrentQuestionIndex(data.current_question_index);
        setTestStatus(data.test_status);
        setLoading(false);
      }
    );
  };

  const completeIt = () => {
    setLoading(true);
    fetch(`/api/v1/tests/${test_id}/complete_it`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        alert('Test completed!');
        setLoading(false);
        setTestStatus('completed'); 
        // onComplete();
      }
    );
  };

  const onQuestionAnswerSubmit = ({current_index, test_question_option_id}) => {
    const newAnswers = [...userAnswers];
    newAnswers[current_index] = test_question_option_id;
    setUserAnswers(newAnswers);
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleBackToQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  };

  const handleCompleteIt = () => {
    completeIt();
  };

  const currentQuestion = questions[currentQuestionIndex];
  const currentAnswer = userAnswers[currentQuestionIndex];

  // if (!currentQuestion) {
  //   return (
  //     <>
  //     {loading && <Loading />}
  //     {!loading && test_status != 'completed' && <CompleteIt onSubmit={handleCompleteIt} />}
  //     </>
  //   );
  // }

  return (<>
    {loading && <Loading />}

    {currentQuestion && <Question
      test_id={test_id}
      test_question={currentQuestion}
      current_index={currentQuestionIndex}
      current_answer={currentAnswer}
      number_of_questions={questions.length}
      onSubmit={onQuestionAnswerSubmit}
      onBackToQuestion={handleBackToQuestion}
    />}

    {!loading && questions.length > 1 && currentQuestionIndex >= questions.length && test_status != 'completed' && (
      <CompleteIt onSubmit={handleCompleteIt} onBack={handleBackToQuestion} />
    )}
    {!loading && test_status == 'completed' && <Completed test_id={test_id} />}

  </>);
};

export default Test;
