import React from 'react';
import { Card, CardContent, CardActions, Button, Typography } from '@mui/material';

const Loading = () => {
  return (
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h5 class="h5">Loading Assessment Information...</h5>
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;