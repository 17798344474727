import React, {useEffect, useState} from "react";
import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const THE_BOOKS = []

const BookList = ({ gate_id }) => {
  const [books, setBooks] = useState(THE_BOOKS)

  useEffect(() => {
    fetch(`/school_gates/gate_logs/featured_books`)
    .then((resp) => {
      if (resp.ok) { return resp.json(); }
      throw new Error("Network response was not ok.");
    })
    .then((resp) => {
      console.log("here:", resp)
      if (resp.status == "ok") {
        setBooks(resp.data)
      } else if (resp.status == "error") {
        setLatestLog({
          error: resp.error
        })
      } else {
        setLatestLog({
          error: "Please Try Again"
        })
      }
      // testEntry()
    })
    .catch((error) => {
      console.log("Catch: ", error.message)
    });
  }, [])

  return(
    <div className="library-books-preview">
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={2000}
        removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
      >
        {books.map((book, index) => {
          return(
            <div className="library-book" key={index}>
              <img src={book} alt="" />
            </div>
          )
        })}
      </Carousel>
    </div>
  )

}
export default BookList;