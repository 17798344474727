import React from "react";

const Scanner = ({ onScan, onCommand }) => {

  const onChange = (e) => {
    // onScan(e.target.value  )
    // console.log(e.target.value)
  }

  const onKeyUp = (e) => {
    if (e.key === 'Enter') {
      console.log('Enter key pressed ' + e.target.value);

      if (e.target.value.length <= 8) {
        // this is a command
        onCommand(e.target.value)
        e.target.value = ""
      } else {
        // this is a rfid/barcode/qr_code
        onScan(e.target.value)
        e.target.value = ""
      }

    }
  }

  return(
    <div style={{marginBottom: "30px"}}>
      <input
        type="text"
        style={{width: "100%"}}
        onChange={onChange}
        onKeyUp={onKeyUp}
        className="library-input"
        style={{
          fontSize: "30px",
          width: "100%",
          border: "1px solid #cccccc",
          borderRadius: "5px",
          padding: "0 10px",
        }}
        autoFocus={true}
      />
    </div>
  )
}

export default Scanner;
