export const API_URL = "/api/v1/school_staffs";

export const ROWS_PER_PAGE = 10;

export const CREATE_FIELDS = [
  {name: "employee_number", label: "Employee Number", type: "text"},
  {name: "first_name", label: "First Name", type: "text"},
  {name: "middle_name", label: "Middle Name", type: "text"},
  {name: "last_name", label: "Last Name", type: "text"},
  {name: "email", label: "Email", type: "text"},
  {name: "mobile_phone", label: "Mobile", type: "text"},
  {name: "rfid_code", label: "RFID Code", type: "text"},
  {name: "position", label: "Position", type: "dropdown", options: [
    {value: "principal", label: "Principal"},
    {value: "teaching", label: "Teaching"},
    {value: "non_teaching", label: "Non-Teaching"},
    {value: "gate", label: "Gate"},
  ]}
]

export const CREATE_FORM_DATA_DEFAULT = {
  employee_number: "",
  first_name: "",
  middle_name: "",
  last_name: "",
  email: "",
  mobile_phone: "",
  rfid_code: "",
  position: "non_teaching",
};

export const LIST_COLUMNS = [
  { id: "employee_number",  label: "Employee Number" },
  { id: "first_name",       label: "First Name" },
  { id: "middle_name",      label: "Middle Name" },
  { id: "last_name",        label: "Last Name" },
  { id: "email",            label: "Email" },
  { id: "position",         label: "Position" },
  { id: "actions",          label: "Actions" },
]

export const UPDATE_FIELDS = [
  {name: "employee_number", label: "Employee Number", type: "text"},
  {name: "first_name", label: "First Name", type: "text"},
  {name: "middle_name", label: "Middle Name", type: "text"},
  {name: "last_name", label: "Last Name", type: "text"},
  {name: "email", label: "Email", type: "text"},
  {name: "mobile_phone", label: "Mobile", type: "text"},
  {name: "rfid_code", label: "RFID Code", type: "text"},
  {name: "position", label: "Position", type: "dropdown", options: [
    {value: "principal", label: "Principal"},
    {value: "teaching", label: "Teaching"},
    {value: "non_teaching", label: "Non-Teaching"},
    {value: "gate", label: "Gate"},
  ]},
  {name: "image", label: "Image", type: "file_upload", url: `${API_URL}/:id/upload_image`},
];

export const SHOW_FIELDS = [
  {name: "id", label: "ID", type: "text"},
  {name: "employee_number", label: "Employee Number", type: "text"},
  {name: "last_name", label: "Last Name", type: "text"},
  {name: "first_name", label: "First Name", type: "text"},
  {name: "middle_name", label: "Middle Name", type: "text"},
  {name: "email", label: "Email", type: "text"},
  {name: "mobile_phone", label: "Mobile", type: "text"},
  {name: "rfid_code", label: "RFID Code", type: "text"},
  {name: "position", label: "Position", type: "text"},
  {name: "image", label: "Image", type: "image_viewer"},
];
