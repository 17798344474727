export const API_URL = "/api/v1/schools";

export const ROWS_PER_PAGE = 10;

export const CREATE_FIELDS = [
  {name: "name", label: "Name", type: "text"},
  {name: "address", label: "Address", type: "text"},
  {name: "school_type", label: "School Type", type: "dropdown", options: [
    {value: "daycare", label: "Daycare"},
    {value: "elementary", label: "Elementary"},
    {value: "highschool", label: "High School"},
  ]},
  {name: "population_count", label: "Population Count", type: "text"},
]

export const CREATE_FORM_DATA_DEFAULT = {
  name: "",
  address: "",
  school_type: "elementary",
  population_count: "",
};

export const LIST_COLUMNS = [
  { id: "name", label: "Name" },
  { id: "address", label: "Address" },
  { id: "school_type", label: "School Type" },
  { id: "population_count", label: "Population Count" },
  { id: "actions", label: "Actions" },
]

export const UPDATE_FIELDS = [
  {name: "name", label: "Name", type: "text"},
  {name: "address", label: "Address", type: "text"},
  {name: "school_type", label: "School Type", type: "dropdown", options: [
    {value: "Daycare", label: "Daycare"},
    {value: "Elementary", label: "Elementary"},
    {value: "High School", label: "High School"},
  ]},
  {name: "population_count", label: "Population Count", type: "text"},
];

export const SHOW_FIELDS = [
  {name: "name", label: "Name", type: "text"},
  {name: "address", label: "Address", type: "text"},
  {name: "school_type", label: "School Type", type: "dropdown", options: [
    {value: "Daycare", label: "Daycare"},
    {value: "Elementary", label: "Elementary"},
    {value: "High School", label: "High School"},
  ]},
  {name: "population_count", label: "Population Count", type: "text"},
];
