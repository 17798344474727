import React from "react";
import BookList from "./_books_list";
import Announcement from "./_announcement";

// const YOUTUBE_ID = "ErpKJFRixB8"
// const YOUTUBE_ID = "myiO8fOr0RI"

const ModeAds = ({ mode, gate_id, youtube_id }) => {

  const theStyle = (mode == "ads") ? {display: "block"} : {display: "none"}

  return(
    <>
    <div style={theStyle}>
      <Announcement />
      <BookList />
    </div>
    <div style={theStyle} className="library-video">
      <iframe
        width="100%"
        height="650"
        src={`https://www.youtube.com/embed/${youtube_id}?rel=0?version=3&autoplay=1&controls=0&&showinfo=0&loop=1`}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        autoplay="1"
      ></iframe>
    </div>
    </>
  )
}

export default ModeAds;
