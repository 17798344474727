import React from 'react';
import { Card, CardContent, CardActions, Button, Typography } from '@mui/material';

const Unstarted = ({ onStartTest }) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div">
          Welcome to the Test!
        </Typography>
        <Typography variant="body1">
          Are you ready to start the test?
        </Typography>
      </CardContent>
      <CardActions>
        <Button variant="contained" color="primary" onClick={onStartTest}>
          Start Test
        </Button>
      </CardActions>
    </Card>
  );
};

export default Unstarted;