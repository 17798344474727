import React, { useState, useEffect } from 'react';
import { Card, CardContent, FormControl, FormControlLabel, Radio, RadioGroup, Typography, Button } from '@mui/material';
import Progress from './_progress';

const Question = ({ test_id, test_question, current_index, current_answer, onSubmit, onBackToQuestion, number_of_questions }) => {
  const [selectedOption, setSelectedOption] = useState('');
  // const [test_question, setTestQuestion] = useState(sample_question);

  useEffect(() => {
    setSelectedOption(current_answer);
  }, [current_index]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = () => {
    submitToApi();
    onSubmit({
      current_index: current_index,
      test_question_option_id: selectedOption
    });
  };

  const handleBackToQuestion = () => {
    if(current_index > 0){
      onBackToQuestion();
    }
  };

  const submitToApi = () => {
    const url = `/api/v1/tests/${test_id}`;
    const body = {
      test_question_id: test_question.id,
      test_question_option_id: selectedOption
    };

    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
      });
  };


  return (
    <>
    <Progress current_index={current_index} number_of_questions={number_of_questions} />
    <Card>
      <CardContent>
        <h5 class="h4 mb-3">{test_question.title}</h5>
          {/* <Typography variant="h5" component="div">
            {test_question.title}
          </Typography> */}
        <FormControl component="fieldset">
          <RadioGroup value={selectedOption} onChange={handleOptionChange}>
            {test_question.options.map((option, index) => (
              <FormControlLabel
                key={index}
                value={option.id}
                control={<Radio />}
                label={option.title}
                checked={selectedOption == option.id}
                style={{marginBottom: '15px'}}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </CardContent>

      <div class="p-3">
        <button class="btn btn-primary btn-lg me-3" disabled={selectedOption == '' || selectedOption == undefined} onClick={handleSubmit}>
          Submit
        </button>

        {current_index > 0 && (
          <button class="btn btn-outline-secondary" onClick={handleBackToQuestion}>
            back
          </button>
        )}
      </div>
    </Card>
    </>
  );
};

export default Question;